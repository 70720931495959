import React, { PropsWithChildren } from 'react';

import { classes } from './Content.st.css';

interface ContentProps extends PropsWithChildren {
  dataHook?: string;
}

export const Content: React.FC<ContentProps> = ({ children, dataHook }) => {
  return (
    <div className={classes.root} data-hook={dataHook}>
      <div className={classes.content}>{children}</div>
    </div>
  );
};
