import React, { PropsWithChildren, useMemo, useState } from 'react';

import { ModalContext, ModalContextValue, ModalElement } from '../contexts';

export const ModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [element, setElement] = useState<null | ModalElement>(null);

  const value = useMemo(
    (): ModalContextValue => ({
      showModal: (modalElement) => {
        setElement(modalElement);
      },
      hideModal: () => {
        setElement(null);
      },
    }),
    [setElement],
  );

  return (
    <ModalContext.Provider value={value}>
      {children}
      {element}
    </ModalContext.Provider>
  );
};
