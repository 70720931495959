import { loyaltyUouClick } from '@wix/bi-logger-loyalty-data/v2';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import React, { useEffect } from 'react';
import { TextButton, TextButtonPriority, ToastSkin } from 'wix-ui-tpa/cssVars';

import { DataHook } from '../../../../types/data-hook';
import { BirthdayState } from '../constants';
import { useControllerProps, useModal, useToast } from '../hooks';
import { classes, st } from './AddBirthdayButton.st.css';
import { AddBirthdayModal } from './AddBirthdayModal';

interface AddBirthdayButtonProps {
  description: string;
  isDetailsCard?: boolean;
}

export const AddBirthdayButton: React.FC<AddBirthdayButtonProps> = ({ description, isDetailsCard }) => {
  const { t } = useTranslation();
  const { showModal, hideModal } = useModal();
  const { showToast } = useToast();
  const { onOpenBirthdayModal, onCloseBirthdayModal, birthdayState } = useControllerProps();
  const bi = useBi();

  const handleCloseModal = () => {
    hideModal();
    onCloseBirthdayModal();
  };

  const handleOpenModal = () => {
    bi.report(
      loyaltyUouClick({
        value: 'add_birthday',
        origin: isDetailsCard ? 'loyalty_page_details_card' : 'loyalty_page_tiers_card',
      }),
    );
    onOpenBirthdayModal();
  };

  useEffect(() => {
    if (birthdayState === BirthdayState.ModalLoaded || birthdayState === BirthdayState.ModalLoading) {
      showModal(<AddBirthdayModal description={description} onClose={handleCloseModal} />);
    }

    if (birthdayState === BirthdayState.SuccessToastVisible || !birthdayState) {
      hideModal();
    }

    if (birthdayState === BirthdayState.SuccessToastVisible || birthdayState === BirthdayState.ErrorToastVisible) {
      const isErrorToast = birthdayState === BirthdayState.ErrorToastVisible;
      showToast({
        message: isErrorToast ? t('app.toast.birthday-added-error') : t('app.toast.birthday-added-success'),
        skin: isErrorToast ? ToastSkin.error : ToastSkin.success,
        timeout: 5000,
      });
    }
  }, [birthdayState]);

  return (
    <TextButton
      data-hook={DataHook.AddBirthdayButton}
      onClick={() => handleOpenModal()}
      className={st(classes.addBirthdayButton, { isDetailsCard })}
      priority={TextButtonPriority.primary}
    >
      {t('app.add-birthday-button')}
    </TextButton>
  );
};
