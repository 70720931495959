import { useEnvironment } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Toast as TPAToast, ToastPlacement, ToastSkin } from 'wix-ui-tpa/cssVars';

import { classes, st } from './Toast.st.css';

interface ToastProps {
  skin: ToastSkin;
  message: string;
  onClose(): void;
}

export const Toast: React.FC<ToastProps> = ({ skin, message, onClose }) => {
  const { isMobile } = useEnvironment();

  return (
    <TPAToast
      skin={skin}
      children={message}
      onClose={onClose}
      className={st(classes.toast, { isMobile })}
      shouldShowCloseButton
      isShown
      placement={isMobile ? ToastPlacement.bottomFullWidth : ToastPlacement.inline}
    />
  );
};
