import React, { PropsWithChildren } from 'react';

import { ControllerPropsContext } from '../contexts';
import { ControllerProps } from '../types';
import { ModalProvider } from './ModalProvider';
import { ToastProvider } from './ToastProvider';

interface WidgetContextProviderProps extends PropsWithChildren {
  controllerProps: ControllerProps;
}

export const WidgetContextProvider: React.FC<WidgetContextProviderProps> = ({ controllerProps, children }) => {
  return (
    <ControllerPropsContext.Provider value={controllerProps}>
      <ModalProvider>
        <ToastProvider>{children}</ToastProvider>
      </ModalProvider>
    </ControllerPropsContext.Provider>
  );
};
