import { Alignment } from '../components/LoyaltyPage/stylesParams';

export interface SimpleRule {
  title: string;
  description: string;
  tierId?: string | null;
  socialMedia?: SocialMediaSettings;
  birthday?: BirthdaySettings;
}

export interface BirthdaySettings {
  description: string;
}

export interface SocialMediaSettings {
  channels: SocialMediaChannel[];
}

export interface SocialMediaChannel {
  type: ChannelType;
  url: string;
}

export interface SimpleReward {
  title: string;
  description: string;
  tierId?: string | null;
}

export interface SimpleTier {
  title: string;
  description: string;
  iconUrl: string;
  requiredPoints?: number;
  id?: string | null;
}

export const RTLAlignmentMap = {
  [Alignment.Left]: Alignment.Right,
  [Alignment.Center]: Alignment.Center,
  [Alignment.Right]: Alignment.Left,
};

export const ChannelTypeConst = {
  Facebook: 'FACEBOOK',
  Instagram: 'INSTAGRAM',
  X: 'X',
  LinkedIn: 'LINKEDIN',
  TikTok: 'TIKTOK',
} as const;

export type ChannelType = (typeof ChannelTypeConst)[keyof typeof ChannelTypeConst];
