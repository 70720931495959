import React from 'react';
import { ToastSkin } from 'wix-ui-tpa/cssVars';

export interface ToastConfig {
  message: string;
  skin: ToastSkin;
  timeout?: number;
}

export interface ToastContextValue {
  showToast(toastConfig: ToastConfig): void;
}

export const ToastContext = React.createContext<ToastContextValue | undefined>(undefined);
