import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

import { defaultBackgroundImage } from '../../constants/default-background-image';

export enum HeaderImageCropPosition {
  TopLeft = 'TOP_LEFT',
  Top = 'TOP',
  TopRight = 'TOP_RIGHT',
  Left = 'LEFT',
  Middle = 'MIDDLE',
  Right = 'RIGHT',
  BottomLeft = 'BOTTOM_LEFT',
  Bottom = 'BOTTOM',
  BottomRight = 'BOTTOM_RIGHT',
}

export type ISettingsParams = {
  headerImage: SettingsParamType.Text; // @todo: Remove this (used as default for new image)
  headerImageNew: SettingsParamType.Text;
  headerImageCropPosition: SettingsParamType.Text;

  programName: SettingsParamType.Text;
  programNameChanged: SettingsParamType.Boolean;
  programTitle: SettingsParamType.Text;
  buttonAnonymous: SettingsParamType.Text;
  buttonLoggedIn: SettingsParamType.Text;

  signUpTitle: SettingsParamType.Text;
  earnPointsTitle: SettingsParamType.Text;
  redeemPointsTitle: SettingsParamType.Text;
  signUpDescription: SettingsParamType.Text;
  earnPointsDescription: SettingsParamType.Text;
  redeemPointsDescription: SettingsParamType.Text;

  tiersTitle: SettingsParamType.Text;
  tiersSubtitle: SettingsParamType.Text;
  currentTierRibbon: SettingsParamType.Text;
};

export default createSettingsParams<ISettingsParams>({
  headerImage: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  headerImageNew: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ getSettingParamValue }) => {
      const image = getSettingParamValue({
        type: SettingsParamType.Text,
        key: 'headerImage',
        getDefaultValue: () => '',
      });
      return image || defaultBackgroundImage;
    },
  },
  headerImageCropPosition: {
    type: SettingsParamType.Text,
    getDefaultValue: () => HeaderImageCropPosition.Middle,
  },
  programName: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.program-name'),
  },
  programNameChanged: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  programTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => {
      return t('app.settings.defaults.program-title-new');
    },
  },
  buttonAnonymous: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.button-anonymous'),
  },
  buttonLoggedIn: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.button-logged-in'),
  },

  signUpTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.sign-up-title'),
  },
  earnPointsTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.earn-points-title'),
  },
  redeemPointsTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.redeem-points-title'),
  },
  signUpDescription: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.sign-up-description'),
  },
  earnPointsDescription: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.earn-points-description'),
  },
  redeemPointsDescription: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.redeem-points-description'),
  },

  tiersTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.tiers.program-title'),
  },
  tiersSubtitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.tiers.program-subtitle'),
  },
  currentTierRibbon: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.tiers.current-tier-ribbon'),
  },
});
