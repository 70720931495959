import React from 'react';

export type ModalElement = React.ReactElement;

export interface ModalContextValue {
  showModal(element: ModalElement): void;
  hideModal(): void;
}

export const ModalContext = React.createContext<ModalContextValue | undefined>(undefined);
