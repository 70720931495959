import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';

export const BulletCheckOutlined = ({ size = 21, color = 'black', settings = false }) => {
  const { t } = useTranslation();
  const pathWidth = 21;
  const pathHeight = 21;
  const viewBoxWidth = 39;
  const viewBoxHeight = 39;
  const ratio = () => size / pathWidth;

  if (settings) {
    return (
      <svg aria-label={t('app.image.role-icon')} viewBox="0 0 38 38">
        <path
          className="cls-b3 illus-clr-2"
          style={{
            transform: `translate(${(viewBoxWidth - pathWidth) / 2}px, ${(viewBoxHeight - pathHeight) / 2}px)`,
          }}
          d="M10.5 0C16.2898 0 21 4.71018 21 10.5C21 16.2898 16.2898 21 10.5 21C4.71018 21 0 16.2898 0 10.5C0 4.71018 4.71018 0 10.5 0ZM10.5 1.23529C5.39082 1.23529 1.23529 5.39082 1.23529 10.5C1.23529 15.6092 5.39082 19.7647 10.5 19.7647C15.6092 19.7647 19.7647 15.6092 19.7647 10.5C19.7647 5.39082 15.6092 1.23529 10.5 1.23529ZM13.9603 7.46426L14.8337 8.33885L9.20072 13.9718L6.1656 10.9367L7.03895 10.0633L9.20072 12.2251L13.9603 7.46426Z"
        />
      </svg>
    );
  }

  return (
    <svg aria-label={t('app.image.role-icon')} className="thumbnail-icon tiny" width={`${size}px`} height={`${size}px`}>
      <path
        className="cls-b6"
        style={{
          transform: `scale(${ratio()}, ${ratio()})`,
        }}
        fill={color}
        d="M10.5 0C16.2898 0 21 4.71018 21 10.5C21 16.2898 16.2898 21 10.5 21C4.71018 21 0 16.2898 0 10.5C0 4.71018 4.71018 0 10.5 0ZM10.5 1.23529C5.39082 1.23529 1.23529 5.39082 1.23529 10.5C1.23529 15.6092 5.39082 19.7647 10.5 19.7647C15.6092 19.7647 19.7647 15.6092 19.7647 10.5C19.7647 5.39082 15.6092 1.23529 10.5 1.23529ZM13.9603 7.46426L14.8337 8.33885L9.20072 13.9718L6.1656 10.9367L7.03895 10.0633L9.20072 12.2251L13.9603 7.46426Z"
      />
    </svg>
  );
};
