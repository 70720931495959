import { createStylesParams, StyleParamType, wixColorParam, wixFontParam } from '@wix/tpa-settings';
import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';

import { getShadowOffsets } from '../../utils/get-shadow-angles';

export enum ButtonStyle {
  EMPTY_ROUNDED = 0,
  EMPTY_CORNERED = 1,
  FULL_ROUNDED = 2,
  FULL_CORNERED = 3,
}

export enum Alignment {
  Left = 0,
  Center = 1,
  Right = 2,
}

export enum PageLayout {
  Grid = 0,
  List = 1,
  Side = 2,
  Mobile = 3,
}

export enum StepThumbnail {
  Numbers = 0,
  Icon = 1,
}

export enum TiersLayout {
  Cards = 0,
  List = 1,
}

export enum PointsTextPosition {
  AboveTitle = 0,
  UnderTitle = 1,
  UnderDescription = 2,
}

export enum BulletStyle {
  None = 0,
  Filled = 1,
  Outlined = 2,
  Check = 3,
  Arrow = 4,
}

export enum CardsPerRow {
  One = 1,
  Two = 2,
  Three = 3,
}

type CssVarsParams = Parameters<CustomCssVarsFn>[0];

const getCssVarsLayoutMap = ({ styleParams }: CssVarsParams) => {
  return {
    mainProgram: {
      [PageLayout.Grid]: {
        root: {
          gridTemplateColumns: '1fr 1fr 1fr',
          gap: `${styleParams.numbers.cardsSpacing}px`,
        },
      },
      [PageLayout.List]: {
        root: {
          gridTemplateColumns: '1fr',
          gap: `${styleParams.numbers.cardsSpacing}px`,
        },
      },
      [PageLayout.Side]: {
        root: {
          gridTemplateColumns: '1fr 1.13fr',
          gap: `${styleParams.numbers.cardsSpacing}px 0px`,
        },
      },
      [PageLayout.Mobile]: {
        root: {
          gridTemplateColumns: '1fr',
          gap: '20px',
        },
      },
    },
    header: {
      [PageLayout.Grid]: {
        root: {
          display: 'block',
          gridColumnStart: 1,
          gridColumnEnd: 4,
          gridRowStart: 1,
          gridRowEnd: 2,
        },
      },
      [PageLayout.List]: {
        root: {
          display: 'block',
          gridColumnStart: 1,
          gridColumnEnd: 2,
          gridRowStart: 1,
          gridRowEnd: 2,
        },
      },
      [PageLayout.Side]: {
        root: {
          display: 'flex',
          gridColumnStart: 1,
          gridColumnEnd: 2,
          gridRowStart: 1,
          gridRowEnd: 4,
        },
      },
      [PageLayout.Mobile]: {
        root: {
          display: 'flex',
          gridColumnStart: 1,
          gridColumnEnd: 2,
          gridRowStart: 1,
          gridRowEnd: 2,
        },
      },
    },
    detailsCard: {
      [PageLayout.Grid]: {
        root: {
          display: 'block',
          minHeight: 'auto',
        },
      },
      [PageLayout.List]: {
        root: {
          display: 'grid',
          minHeight: `${260 - styleParams.numbers.cardsPadding * 2}px`,
        },
      },
      [PageLayout.Side]: {
        root: {
          display: 'flex',
          minHeight: `${260 - styleParams.numbers.cardsPadding * 2}px`,
        },
      },
      [PageLayout.Mobile]: {
        root: {
          display: 'block',
          minHeight: 'auto',
        },
      },
    },
    tierCards: {
      tierCard: {
        [TiersLayout.Cards]: {
          [CardsPerRow.One]: {
            width: '100%',
          },
          [CardsPerRow.Two]: {
            width: `calc((100% / 2) - ${styleParams.numbers.tiersCardsSpacing}px / 2 )`,
          },
          [CardsPerRow.Three]: {
            width: `calc((100% / 3) - ${styleParams.numbers.tiersCardsSpacing}px / 3 * 2 )`,
          },
        },
        [TiersLayout.List]: {
          width: '100%',
        },
      },
    },
  };
};

const itemsAlignmentMap = {
  [Alignment.Left]: 'flex-start',
  [Alignment.Center]: 'center',
  [Alignment.Right]: 'flex-end',
};

const textAlignmentMap = {
  [Alignment.Left]: 'start',
  [Alignment.Center]: 'center',
  [Alignment.Right]: 'end',
};

const alignmentMap = {
  header: {
    [Alignment.Left]: {
      content: {
        alignItems: itemsAlignmentMap[Alignment.Left],
        textAlign: textAlignmentMap[Alignment.Left],
      },
    },
    [Alignment.Center]: {
      content: {
        alignItems: itemsAlignmentMap[Alignment.Center],
        textAlign: textAlignmentMap[Alignment.Center],
      },
    },
    [Alignment.Right]: {
      content: {
        alignItems: itemsAlignmentMap[Alignment.Right],
        textAlign: textAlignmentMap[Alignment.Right],
      },
    },
  },
  detailsCard: {
    [Alignment.Left]: {
      header: {
        alignItems: itemsAlignmentMap[Alignment.Left],
      },
      step: {
        textAlign: textAlignmentMap[Alignment.Left],
      },
      title: {
        textAlign: textAlignmentMap[Alignment.Left],
      },
      details: {
        textAlign: textAlignmentMap[Alignment.Left],
      },
    },
    [Alignment.Center]: {
      header: {
        alignItems: itemsAlignmentMap[Alignment.Center],
      },
      step: {
        textAlign: textAlignmentMap[Alignment.Center],
      },
      title: {
        textAlign: textAlignmentMap[Alignment.Center],
      },
      details: {
        textAlign: textAlignmentMap[Alignment.Center],
      },
    },
    [Alignment.Right]: {
      header: {
        alignItems: itemsAlignmentMap[Alignment.Right],
      },
      step: {
        textAlign: textAlignmentMap[Alignment.Right],
      },
      title: {
        textAlign: textAlignmentMap[Alignment.Right],
      },
      details: {
        textAlign: textAlignmentMap[Alignment.Right],
      },
    },
  },
  tiersProgram: {
    [Alignment.Left]: {
      headerSection: {
        alignItems: itemsAlignmentMap[Alignment.Left],
      },
      sectionTitle: {
        textAlign: textAlignmentMap[Alignment.Left],
      },
      sectionSubtitle: {
        textAlign: textAlignmentMap[Alignment.Left],
      },
    },
    [Alignment.Center]: {
      headerSection: {
        alignItems: itemsAlignmentMap[Alignment.Center],
      },
      sectionTitle: {
        textAlign: textAlignmentMap[Alignment.Center],
      },
      sectionSubtitle: {
        textAlign: textAlignmentMap[Alignment.Center],
      },
    },
    [Alignment.Right]: {
      headerSection: {
        alignItems: itemsAlignmentMap[Alignment.Right],
      },
      sectionTitle: {
        textAlign: textAlignmentMap[Alignment.Right],
      },
      sectionSubtitle: {
        textAlign: textAlignmentMap[Alignment.Right],
      },
    },
  },
  tierCards: {
    [Alignment.Left]: {
      tierCardHeader: {
        alignItems: itemsAlignmentMap[Alignment.Left],
      },
      tierCardPoints: {
        textAlign: textAlignmentMap[Alignment.Left],
      },
      tierCardTitleContainer: {
        justifyContent: 'normal',
      },
      tierCardIcon: {
        marginInlineStart: 0,
        marginInlineEnd: '15px',
      },
      tierCardTitle: {
        textAlign: textAlignmentMap[Alignment.Left],
      },
      tierCardDescription: {
        textAlign: textAlignmentMap[Alignment.Left],
      },
      sectionsContainer: {
        justifyContent: 'start',
      },
      section: {
        alignItems: itemsAlignmentMap[Alignment.Left],
      },
      sectionTitle: {
        textAlign: textAlignmentMap[Alignment.Left],
      },
      detailContainer: {
        justifyContent: 'flex-start',
      },
      detailTextContainer: {
        textAlign: textAlignmentMap[Alignment.Left],
      },
    },
    [Alignment.Center]: {
      tierCardHeader: {
        alignItems: itemsAlignmentMap[Alignment.Center],
      },
      tierCardPoints: {
        textAlign: textAlignmentMap[Alignment.Center],
      },
      tierCardTitleContainer: {
        justifyContent: 'center',
      },
      tierCardIcon: {
        marginInlineStart: 0,
        marginInlineEnd: '15px',
      },
      tierCardTitle: {
        textAlign: textAlignmentMap[Alignment.Center],
      },
      tierCardDescription: {
        textAlign: textAlignmentMap[Alignment.Center],
      },
      sectionsContainer: {
        justifyContent: 'center',
      },
      section: {
        alignItems: itemsAlignmentMap[Alignment.Center],
      },
      sectionTitle: {
        textAlign: textAlignmentMap[Alignment.Center],
      },
      detailContainer: {
        justifyContent: 'center',
      },
      detailTextContainer: {
        textAlign: textAlignmentMap[Alignment.Center],
      },
    },
    [Alignment.Right]: {
      tierCardHeader: {
        alignItems: itemsAlignmentMap[Alignment.Right],
      },
      tierCardPoints: {
        textAlign: textAlignmentMap[Alignment.Right],
      },
      tierCardTitleContainer: {
        justifyContent: 'normal',
      },
      tierCardIcon: {
        marginInlineStart: '15px',
        marginInlineEnd: 0,
      },
      tierCardTitle: {
        textAlign: textAlignmentMap[Alignment.Right],
      },
      tierCardDescription: {
        textAlign: textAlignmentMap[Alignment.Right],
      },
      sectionsContainer: {
        justifyContent: 'end',
      },
      section: {
        alignItems: itemsAlignmentMap[Alignment.Right],
      },
      sectionTitle: {
        textAlign: textAlignmentMap[Alignment.Right],
      },
      detailContainer: {
        justifyContent: 'normal',
      },
      detailTextContainer: {
        textAlign: textAlignmentMap[Alignment.Right],
      },
    },
  },
};

type IStylesParams = {
  showHeader: StyleParamType.Boolean;
  showCoverImage: StyleParamType.Boolean;
  showProgramName: StyleParamType.Boolean;
  showProgramTitle: StyleParamType.Boolean;
  showProgramContent: StyleParamType.Boolean;
  showStepIndication: StyleParamType.Boolean;
  showTiers: StyleParamType.Boolean;
  showTiersPageTitle: StyleParamType.Boolean;
  showTiersPageSubtitle: StyleParamType.Boolean;
  showCurrentTier: StyleParamType.Boolean;
  showSocialMediaIcons: StyleParamType.Boolean;

  pageLayout: StyleParamType.Number;

  headerHeight: StyleParamType.Number;
  headerSidePadding: StyleParamType.Number;
  headerAlignment: StyleParamType.Number;
  headerSplit: StyleParamType.Boolean;

  cardsSpacing: StyleParamType.Number;
  cardsPosition: StyleParamType.Number;
  cardsMargin: StyleParamType.Number;
  cardsStepThumbnail: StyleParamType.Number;
  cardsContentAlignment: StyleParamType.Number;
  cardsPadding: StyleParamType.Number;

  tiersLayout: StyleParamType.Number;
  tiersHeaderAlignment: StyleParamType.Number;
  tiersCardsPerRow: StyleParamType.Number;
  tiersCardsSpacing: StyleParamType.Number;
  tiersCardsPadding: StyleParamType.Number;
  tiersContentAlignment: StyleParamType.Number;
  tiersShowIcon: StyleParamType.Boolean;
  tiersShowDescription: StyleParamType.Boolean;
  tiersPointsTextPosition: StyleParamType.Number;
  tiersBulletsStyle: StyleParamType.Number;

  headerBackgroundColorNew: StyleParamType.Color;
  headerImageOpacity: StyleParamType.Number;
  headerBorderRadius: StyleParamType.Number;
  headerSplitBackgroundColor: StyleParamType.Color;

  programNameFontNew: StyleParamType.Font;
  programNameColorNew: StyleParamType.Color;
  programTitleFontNew: StyleParamType.Font;
  programTitleColorNew: StyleParamType.Color;

  headerShowShadow: StyleParamType.Boolean;
  headerShadowAngle: StyleParamType.Number;
  headerShadowDistance: StyleParamType.Number;
  headerShadowBlur: StyleParamType.Number;
  headerShadowColor: StyleParamType.Color;

  buttonTextFont: StyleParamType.Font;
  buttonTextColorNew: StyleParamType.Color;
  buttonBackgroundColorNew: StyleParamType.Color;
  buttonBorderColorNew: StyleParamType.Color;
  buttonBorderWidth: StyleParamType.Number;
  buttonBorderRadius: StyleParamType.Number;

  detailsBackgroundColor: StyleParamType.Color;
  detailsBorderWidth: StyleParamType.Number;
  detailsBorderColor: StyleParamType.Color;
  detailsCornerRadius: StyleParamType.Number;

  detailsShowShadow: StyleParamType.Boolean;
  detailsShadowAngle: StyleParamType.Number;
  detailsShadowDistance: StyleParamType.Number;
  detailsShadowBlur: StyleParamType.Number;
  detailsShadowColor: StyleParamType.Color;

  detailsStepIndicationFont: StyleParamType.Font;
  detailsStepIndicationColor: StyleParamType.Color;
  detailsStepFont: StyleParamType.Font;
  detailsStepColor: StyleParamType.Color;
  detailsTitleFont: StyleParamType.Font;
  detailsTitleColor: StyleParamType.Color;
  detailsDescriptionFont: StyleParamType.Font;
  detailsDescriptionColor: StyleParamType.Color;
  detailsLinkColor: StyleParamType.Color;
  detailsLinkFont: StyleParamType.Font;
  detailsSocialIconColor: StyleParamType.Color;

  tiersBackgroundColor: StyleParamType.Color;
  tiersBorderWidth: StyleParamType.Number;
  tiersBorderColor: StyleParamType.Color;
  tiersCornerRadius: StyleParamType.Number;
  tiersShowShadow: StyleParamType.Boolean;
  tiersShadowAngle: StyleParamType.Number;
  tiersShadowDistance: StyleParamType.Number;
  tiersShadowBlur: StyleParamType.Number;
  tiersShadowColor: StyleParamType.Color;
  tiersDividerWidth: StyleParamType.Number;
  tiersDividerColor: StyleParamType.Color;
  tiersSectionTitleFont: StyleParamType.Font;
  tiersSectionTitleColor: StyleParamType.Color;
  tiersSectionSubtitleFont: StyleParamType.Font;
  tiersSectionSubtitleColor: StyleParamType.Color;
  tierTitleFont: StyleParamType.Font;
  tierTitleColor: StyleParamType.Color;
  tierDescriptionFont: StyleParamType.Font;
  tierDescriptionColor: StyleParamType.Color;
  tierPointsTextFont: StyleParamType.Font;
  tierPointsTextColor: StyleParamType.Color;
  tierSectionTitleFont: StyleParamType.Font;
  tierSectionTitleColor: StyleParamType.Color;
  tierRoleTitleFont: StyleParamType.Font;
  tierRoleTitleColor: StyleParamType.Color;
  tierRoleDescriptionFont: StyleParamType.Font;
  tierRoleDescriptionColor: StyleParamType.Color;

  currentTierHeaderBackgroundColor: StyleParamType.Color;
  currentTierRibbonBackgroundColor: StyleParamType.Color;
  currentTierTitleFont: StyleParamType.Font;
  currentTierTitleColor: StyleParamType.Color;
  currentTierDescriptionFont: StyleParamType.Font;
  currentTierDescriptionColor: StyleParamType.Color;
  currentTierRibbonTextFont: StyleParamType.Font;
  currentTierRibbonTextColor: StyleParamType.Color;
  currentTierPointsTextFont: StyleParamType.Font;
  currentTierPointsTextColor: StyleParamType.Color;
};

export default createStylesParams<IStylesParams>({
  showHeader: { type: StyleParamType.Boolean, getDefaultValue: () => true },
  showCoverImage: { type: StyleParamType.Boolean, getDefaultValue: () => true },
  showProgramName: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showProgramTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showProgramContent: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showStepIndication: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showTiers: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showTiersPageTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showTiersPageSubtitle: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showCurrentTier: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  showSocialMediaIcons: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },

  pageLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => PageLayout.Grid,
  },

  headerHeight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 300,
  },
  headerSidePadding: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 20 : 40),
  },
  headerAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: (params) => {
      if (params.isMobile) {
        return Alignment.Center;
      }

      const layout = params.getStyleParamValue({ type: StyleParamType.Number, key: 'pageLayout' });
      return layout === PageLayout.Side ? Alignment.Left : Alignment.Center;
    },
  },
  headerSplit: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },

  cardsSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: () => 30,
  },
  cardsPosition: {
    type: StyleParamType.Number,
    getDefaultValue: () => 30,
  },
  cardsMargin: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  cardsStepThumbnail: {
    type: StyleParamType.Number,
    getDefaultValue: () => StepThumbnail.Numbers,
  },
  cardsContentAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: (params) => {
      if (params.isMobile) {
        return Alignment.Left;
      }

      const layout = params.getStyleParamValue({ type: StyleParamType.Number, key: 'pageLayout' });
      return layout === PageLayout.Grid || !layout ? Alignment.Center : Alignment.Left;
    },
  },
  cardsPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 32,
  },

  tiersLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => TiersLayout.Cards,
  },
  tiersHeaderAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => Alignment.Center,
  },
  tiersCardsPerRow: {
    type: StyleParamType.Number,
    getDefaultValue: () => CardsPerRow.Three,
  },
  tiersCardsSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: () => 30,
  },
  tiersCardsPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 32,
  },
  tiersContentAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => Alignment.Left,
  },
  tiersShowIcon: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  tiersShowDescription: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  tiersPointsTextPosition: {
    type: StyleParamType.Number,
    getDefaultValue: () => PointsTextPosition.AboveTitle,
  },
  tiersBulletsStyle: {
    type: StyleParamType.Number,
    getDefaultValue: () => BulletStyle.Outlined,
  },

  headerBackgroundColorNew: {
    type: StyleParamType.Color,
    getDefaultValue: () => ({
      value: '#000000',
      opacity: 1,
      name: null,
    }),
  },
  headerImageOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: () => 60,
  },
  headerBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  headerSplitBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0),
  },

  programNameFontNew: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 32,
    }),
  },
  programNameColorNew: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  programTitleFontNew: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  programTitleColorNew: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },

  headerShowShadow: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  headerShadowAngle: {
    type: StyleParamType.Number,
    getDefaultValue: () => 135,
  },
  headerShadowDistance: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  headerShadowBlur: {
    type: StyleParamType.Number,
    getDefaultValue: () => 25,
  },
  headerShadowColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.15),
  },

  buttonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  buttonTextColorNew: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  buttonBackgroundColorNew: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonBorderColorNew: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  buttonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },

  detailsBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  detailsBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  detailsBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  detailsCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },

  detailsShowShadow: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  detailsShadowAngle: {
    type: StyleParamType.Number,
    getDefaultValue: () => 135,
  },
  detailsShadowDistance: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  detailsShadowBlur: {
    type: StyleParamType.Number,
    getDefaultValue: () => 25,
  },
  detailsShadowColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.15),
  },

  detailsStepIndicationFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 20,
    }),
  },
  detailsStepIndicationColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  detailsStepFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-XL', {
      size: 24,
    }),
  },
  detailsStepColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  detailsTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-XL', {
      size: 16,
    }),
  },
  detailsTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  detailsDescriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  detailsDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  detailsLinkColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  detailsLinkFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  detailsSocialIconColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },

  tiersBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  tiersBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  tiersBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  tiersCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  tiersShowShadow: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  tiersShadowAngle: {
    type: StyleParamType.Number,
    getDefaultValue: () => 135,
  },
  tiersShadowDistance: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  tiersShadowBlur: {
    type: StyleParamType.Number,
    getDefaultValue: () => 25,
  },
  tiersShadowColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.15),
  },
  tiersDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  tiersDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  tiersSectionTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 32,
    }),
  },
  tiersSectionTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  tiersSectionSubtitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  tiersSectionSubtitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  tierTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 28,
    }),
  },
  currentTierTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ getStyleParamValue }) =>
      getStyleParamValue({ type: StyleParamType.Font, key: 'tierTitleFont' }) ??
      wixFontParam('Page-title', { size: 28 }),
  },
  tierTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  currentTierTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue }) =>
      getStyleParamValue({ type: StyleParamType.Color, key: 'tierTitleColor' }) ?? wixColorParam('color-5'),
  },
  tierDescriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  currentTierDescriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ getStyleParamValue }) =>
      getStyleParamValue({ type: StyleParamType.Font, key: 'tierDescriptionFont' }) ??
      wixFontParam('Body-M', { size: 16 }),
  },
  tierDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  currentTierDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue }) =>
      getStyleParamValue({ type: StyleParamType.Color, key: 'tierDescriptionColor' }) ?? wixColorParam('color-5'),
  },
  tierPointsTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  currentTierPointsTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ getStyleParamValue }) =>
      getStyleParamValue({ type: StyleParamType.Font, key: 'tierPointsTextFont' }) ??
      wixFontParam('Body-M', { size: 16 }),
  },
  tierPointsTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  currentTierPointsTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: (params) =>
      params.getStyleParamValue({ type: StyleParamType.Color, key: 'tierPointsTextColor' }) ?? wixColorParam('color-4'),
  },
  tierSectionTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  tierSectionTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  tierRoleTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  tierRoleTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  tierRoleDescriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  tierRoleDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  currentTierHeaderBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.1),
  },
  currentTierRibbonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  currentTierRibbonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  currentTierRibbonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
});

export const customCssVars: CustomCssVarsFn = (params) => {
  const { styleParams, isMobile, isRTL } = params;
  const pageLayoutMap = getCssVarsLayoutMap(params);
  const pageLayout = isMobile ? PageLayout.Mobile : (styleParams.numbers.pageLayout as PageLayout);
  const mainProgramPageLayout =
    !styleParams.booleans.showHeader || !styleParams.booleans.showProgramContent ? PageLayout.List : pageLayout;
  const detailsCardPageLayout = isMobile ? PageLayout.Grid : pageLayout;
  const headerAlignment = styleParams.numbers.headerAlignment as Alignment;
  const detailsCardAlignment = styleParams.numbers.cardsContentAlignment as Alignment;
  const headerSplit = styleParams.booleans.headerSplit && !isMobile && pageLayout === PageLayout.List;
  const tierCardsLayout = isMobile ? TiersLayout.Cards : (styleParams.numbers.tiersLayout as TiersLayout);
  const tiersHeaderAlignment = styleParams.numbers.tiersHeaderAlignment as Alignment;
  const tierCardsAlignment = styleParams.numbers.tiersContentAlignment as Alignment;
  const tierCardsLayoutIsListAndCentered =
    tierCardsLayout === TiersLayout.List && tierCardsAlignment === Alignment.Center;
  const showCurrentTier = styleParams.booleans.showCurrentTier;
  const showTiers = styleParams.booleans.showTiers;

  const getHeaderRootBoxShadow = () => {
    const { xOffset, yOffset } = getShadowOffsets({
      angle: styleParams.numbers.headerShadowAngle,
      distance: styleParams.numbers.headerShadowDistance,
    });

    return `${xOffset}px ${yOffset}px ${styleParams.numbers.headerShadowBlur}px ${styleParams.colors.headerShadowColor}`;
  };

  const getDetailsRootBoxShadow = () => {
    const { xOffset, yOffset } = getShadowOffsets({
      angle: styleParams.numbers.detailsShadowAngle,
      distance: styleParams.numbers.detailsShadowDistance,
    });

    return `${xOffset}px ${yOffset}px ${styleParams.numbers.detailsShadowBlur}px ${styleParams.colors.detailsShadowColor}`;
  };

  const getTierCardsBoxShadow = () => {
    const { xOffset, yOffset } = getShadowOffsets({
      angle: styleParams.numbers.tiersShadowAngle,
      distance: styleParams.numbers.tiersShadowDistance,
    });

    return `${xOffset}px ${yOffset}px ${styleParams.numbers.tiersShadowBlur}px ${styleParams.colors.tiersShadowColor}`;
  };

  const remapFont = (newVarName: string, oldVarName: string) => ({
    [`${newVarName}`]: `var(--${oldVarName})`,
    [`${newVarName}-text-decoration`]: `var(--${oldVarName}-text-decoration)`,
    [`${newVarName}-line-height`]: `var(--${oldVarName}-line-height)`,
    [`${newVarName}-family`]: `var(--${oldVarName}-family)`,
    [`${newVarName}-style`]: `var(--${oldVarName}-style)`,
    [`${newVarName}-size`]: `var(--${oldVarName}-size)`,
    [`${newVarName}-variant`]: `var(--${oldVarName}-variant)`,
    [`${newVarName}-weight`]: `var(--${oldVarName}-weight)`,
  });

  return {
    // main program
    mainProgramRootDirection: isRTL ? 'rtl' : 'ltr',
    mainProgramRootGridTemplateColumns: pageLayoutMap.mainProgram[mainProgramPageLayout].root.gridTemplateColumns,
    mainProgramRootGap: pageLayoutMap.mainProgram[mainProgramPageLayout].root.gap,
    mainProgramRootMarginBottom:
      mainProgramPageLayout === PageLayout.Grid ? `${styleParams.numbers.cardsSpacing}px` : '0px',
    mainProgramRootJustifyContent: isMobile ? 'center' : 'normal',
    mainProgramDetailsContainerDisplay: styleParams.booleans.showProgramContent ? 'contents' : 'none',

    // header
    headerRootDisplay: styleParams.booleans.showHeader
      ? headerSplit
        ? 'flex'
        : pageLayoutMap.header[pageLayout].root.display
      : 'none',
    headerRootFlexDirection: headerSplit ? 'row-reverse' : 'row',
    headerRootGridColumnStart: pageLayoutMap.header[pageLayout].root.gridColumnStart,
    headerRootGridColumnEnd: pageLayoutMap.header[pageLayout].root.gridColumnEnd,
    headerRootGridRowStart: pageLayoutMap.header[pageLayout].root.gridRowStart,
    headerRootGridRowEnd: pageLayoutMap.header[pageLayout].root.gridRowEnd,
    headerRootBoxShadow: styleParams.booleans.headerShowShadow ? getHeaderRootBoxShadow() : 'none',
    headerBackgroundImageDisplay: styleParams.booleans.showCoverImage ? 'block' : 'none',
    headerBackgroundImagePosition: headerSplit ? 'relative' : 'absolute',
    headerBackgroundImageWidth: headerSplit ? '50%' : 'auto',
    headerContentWidth: pageLayout === PageLayout.Side ? (headerSplit ? '50%' : '100%') : 'auto',
    headerContentAlignItems: alignmentMap.header[headerAlignment].content.alignItems,
    headerContentTextAlign: alignmentMap.header[headerAlignment].content.textAlign,
    headerContentSidePadding: `${styleParams.numbers.headerSidePadding}px`,
    headerIconDisplay: headerSplit ? 'block' : 'none',
    headerTitleDisplay: styleParams.booleans.showProgramName ? 'block' : 'none',
    headerSubtitleDisplay: styleParams.booleans.showProgramTitle ? 'block' : 'none',

    // details card
    detailsCardRootDisplay: pageLayoutMap.detailsCard[detailsCardPageLayout].root.display,
    detailsCardRootPadding: `${styleParams.numbers.cardsPadding}px`,
    detailsCardRootMinHeight: pageLayoutMap.detailsCard[detailsCardPageLayout].root.minHeight,
    detailsCardRootMarginTop: detailsCardPageLayout === PageLayout.Side ? `${styleParams.numbers.cardsMargin}px` : 0,
    detailsCardRootMarginBottom: detailsCardPageLayout === PageLayout.Side ? `${styleParams.numbers.cardsMargin}px` : 0,
    detailsCardRootMarginInlineStart:
      detailsCardPageLayout === PageLayout.Side ? `${styleParams.numbers.cardsPosition}px` : 0,
    detailsCardRootFlexDirection: detailsCardPageLayout === PageLayout.Side ? 'column' : 'row',
    detailsCardRootZIndex: detailsCardPageLayout === PageLayout.Side ? 9 : 'auto',
    detailsCardRootGridTemplateColumns: detailsCardPageLayout === PageLayout.List ? '1fr 1fr' : 'none',
    detailsCardRootAlignItems: detailsCardPageLayout === PageLayout.List ? 'center' : 'normal',
    detailsCardRootBoxShadow: styleParams.booleans.detailsShowShadow ? getDetailsRootBoxShadow() : 'none',
    detailsCardHeaderMarginBottom: detailsCardPageLayout === PageLayout.List ? 'initial' : '40px',
    detailsCardHeaderAlignItems: alignmentMap.detailsCard[detailsCardAlignment].header.alignItems,
    detailsCardStepIndicationDisplay: styleParams.booleans.showStepIndication ? 'block' : 'none',
    detailsCardStepDisplay: styleParams.numbers.cardsStepThumbnail === StepThumbnail.Numbers ? 'block' : 'none',
    detailsCardStepMarginBottom: detailsCardPageLayout === PageLayout.Grid ? '40px' : '20px',
    detailsCardStepTextAlign: alignmentMap.detailsCard[detailsCardAlignment].step.textAlign,
    detailsCardIconDisplay: styleParams.numbers.cardsStepThumbnail === StepThumbnail.Icon ? 'block' : 'none',
    detailsCardIconMarginBottom: detailsCardPageLayout === PageLayout.Grid ? '40px' : '20px',
    detailsCardTitleTextAlign: alignmentMap.detailsCard[detailsCardAlignment].title.textAlign,
    detailsCardDetailsTextAlign: alignmentMap.detailsCard[detailsCardAlignment].details.textAlign,

    // social media
    socialMediaIconRootDisplay: styleParams.booleans.showSocialMediaIcons ? 'block' : 'none',
    socialMediaIconStartPadding:
      showTiers && tierCardsAlignment === Alignment.Left && styleParams.numbers.tiersBulletsStyle !== BulletStyle.None
        ? '24px'
        : '0',
    socialMediaChannelIdColor: showTiers ? 'var(--tierRoleDescriptionColor)' : 'var(--detailsDescriptionColor)',
    ...remapFont('socialMediaChannelIdFont', showTiers ? 'tierRoleDescriptionFont' : 'detailsDescriptionFont'),

    // tiers program
    tiersProgramRootDisplay: showTiers ? 'flex' : 'none',
    tiersProgramSectionTitleDisplay: styleParams.booleans.showTiersPageTitle ? 'block' : 'none',
    tiersProgramHeaderSectionAlignItems: alignmentMap.tiersProgram[tiersHeaderAlignment].headerSection.alignItems,
    tiersProgramSectionTitleMargin: styleParams.booleans.showTiersPageSubtitle ? '12px' : '30px',
    tiersProgramSectionTitleTextAlign: alignmentMap.tiersProgram[tiersHeaderAlignment].sectionTitle.textAlign,
    tiersProgramSectionSubtitleDisplay: styleParams.booleans.showTiersPageSubtitle ? 'block' : 'none',
    tiersProgramSectionSubtitleTextAlign: alignmentMap.tiersProgram[tiersHeaderAlignment].sectionSubtitle.textAlign,

    // tier cards
    tierCardsRootDirection: isRTL ? 'rtl' : 'ltr',
    tierCardsTierCardWidth: isMobile
      ? '100%'
      : styleParams.numbers.tiersLayout === TiersLayout.Cards
      ? pageLayoutMap.tierCards.tierCard[TiersLayout.Cards][styleParams.numbers.tiersCardsPerRow as CardsPerRow].width
      : pageLayoutMap.tierCards.tierCard[TiersLayout.List].width,
    tierCardsTierCardBoxShadow: styleParams.booleans.tiersShowShadow ? getTierCardsBoxShadow() : 'none',
    tierCardsTierCardHeaderAlignItems: alignmentMap.tierCards[tierCardsAlignment].tierCardHeader.alignItems,
    tierCardsTierCardPointsTextAboveTitleDisplay:
      styleParams.numbers.tiersPointsTextPosition === PointsTextPosition.AboveTitle ? 'block' : 'none',
    tierCardsTierCardPointsTextUnderTitleDisplay:
      styleParams.numbers.tiersPointsTextPosition === PointsTextPosition.UnderTitle ? 'block' : 'none',
    tierCardsTierCardPointsTextUnderDescDisplay:
      styleParams.numbers.tiersPointsTextPosition === PointsTextPosition.UnderDescription ? 'block' : 'none',
    tierCardsTierCardPointsTextAlign: alignmentMap.tierCards[tierCardsAlignment].tierCardPoints.textAlign,
    tierCardsTierCardPointsMarginTop:
      styleParams.numbers.tiersPointsTextPosition !== PointsTextPosition.AboveTitle ? '12px' : 0,
    tierCardsTierCardPointsMarginBottom:
      styleParams.numbers.tiersPointsTextPosition === PointsTextPosition.AboveTitle ? '8px' : 0,
    tierCardsTierCardTitleContainerJustifyContent:
      alignmentMap.tierCards[tierCardsAlignment].tierCardTitleContainer.justifyContent,
    tierCardsTierCardTitleContainerFlexDirection:
      styleParams.numbers.tiersContentAlignment === Alignment.Right ? 'row-reverse' : 'row',
    tierCardsTierCardIconDisplay: styleParams.booleans.tiersShowIcon ? 'block' : 'none',
    tierCardsTierCardIconMarginInlineStart: alignmentMap.tierCards[tierCardsAlignment].tierCardIcon.marginInlineStart,
    tierCardsTierCardIconMarginInlineEnd: alignmentMap.tierCards[tierCardsAlignment].tierCardIcon.marginInlineEnd,
    tierCardsTierCardTitleTextAlign: alignmentMap.tierCards[tierCardsAlignment].tierCardTitle.textAlign,
    tierCardsTierCardDescriptionContainerDisplay: styleParams.booleans.tiersShowDescription ? 'flex' : 'none',
    tierCardsTierCardDescriptionTextAlign: alignmentMap.tierCards[tierCardsAlignment].tierCardDescription.textAlign,
    tierCardsSectionsContainerDisplay: tierCardsLayout === TiersLayout.List ? 'flex' : 'block',
    tierCardsSectionsContainerJustifyContent:
      tierCardsLayout === TiersLayout.List
        ? alignmentMap.tierCards[tierCardsAlignment].sectionsContainer.justifyContent
        : 'normal',
    tierCardsSectionAlignItems: tierCardsLayoutIsListAndCentered
      ? 'flex-start'
      : alignmentMap.tierCards[tierCardsAlignment].section.alignItems,
    tierCardsSectionWidth: tierCardsLayout === TiersLayout.List ? '290px' : 'auto',
    tierCardsSectionMarginRight: tierCardsLayout === TiersLayout.List ? '48px' : 0,
    tierCardsSectionMarginBottom: tierCardsLayout === TiersLayout.List ? 0 : '32px',
    tierCardsSectionTitle: tierCardsLayoutIsListAndCentered
      ? 'center'
      : alignmentMap.tierCards[tierCardsAlignment].sectionTitle.textAlign,
    tierCardsDetailContainerJustifyContent: tierCardsLayoutIsListAndCentered
      ? 'flex-start'
      : alignmentMap.tierCards[tierCardsAlignment].detailContainer.justifyContent,
    tierCardsDetailContainerFlexDirection:
      styleParams.numbers.tiersContentAlignment === Alignment.Right ? 'row-reverse' : 'row',
    tierCardsDetailBulletContainerMarginInlineStart: tierCardsAlignment === Alignment.Right ? '12px' : 0,
    tierCardsDetailBulletContainerMarginInlineEnd: tierCardsAlignment === Alignment.Right ? 0 : '12px',
    tierCardsDetailTextContainerTextAlignment: tierCardsLayoutIsListAndCentered
      ? 'start'
      : alignmentMap.tierCards[tierCardsAlignment].detailTextContainer.textAlign,

    // current tier
    currentTierRootDisplay: showCurrentTier ? 'block' : 'none',
    currentTierRootHeaderBackgroundColor: showCurrentTier
      ? styleParams.colors.currentTierHeaderBackgroundColor
      : 'unset',
    ...remapFont('currentTierRootTitleFont', showCurrentTier ? 'currentTierTitleFont' : 'tierTitleFont'),
    currentTierRootTitleColor: showCurrentTier ? 'var(--currentTierTitleColor)' : 'var(--tierTitleColor)',
    ...remapFont(
      'currentTierRootDescriptionFont',
      showCurrentTier ? 'currentTierDescriptionFont' : 'tierDescriptionFont',
    ),
    currentTierRootDescriptionColor: showCurrentTier
      ? 'var(--currentTierDescriptionColor)'
      : 'var(--tierDescriptionColor)',
    ...remapFont('currentTierRootPointsTextFont', showCurrentTier ? 'currentTierPointsTextFont' : 'tierPointsTextFont'),
    currentTierRootPointsTextColor: showCurrentTier
      ? 'var(--currentTierPointsTextColor)'
      : 'var(--tierPointsTextColor)',
  };
};
