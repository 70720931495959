import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import React from 'react';

import { Experiment } from '../../../constants';
import { DataHook } from '../../../types/data-hook';
import { ChannelType, SimpleReward, SimpleRule } from '../../../types/domain';
import { TabState } from '../../../types/settings';
import settingsParams from '../settingsParams';
import { DetailsCard } from './DetailsCard';
import { Header } from './Header';
import { classes } from './MainProgram.st.css';

interface MainProgramProps {
  tabState: TabState;
  loyaltyProgram: LoyaltyProgram;
  isLoggedIn: boolean;
  hasMyRewardsPage: boolean;
  onNavigateToMyRewardsPage(): void;
  onFollowSocialMediaChannel(type: ChannelType): void;
  simpleRules: SimpleRule[];
  simpleRewards: SimpleReward[];
  showTiers: boolean;
  followedChannels: ChannelType[];
}

export const MainProgram: React.FC<MainProgramProps> = ({
  tabState,
  loyaltyProgram,
  isLoggedIn,
  hasMyRewardsPage,
  onNavigateToMyRewardsPage,
  onFollowSocialMediaChannel,
  simpleRules,
  simpleRewards,
  showTiers,
  followedChannels,
}) => {
  const styles = useStyles();
  const settings = useSettings();
  const { isViewer } = useEnvironment();
  const { experiments } = useExperiments();

  const iconPath = loyaltyProgram?.pointDefinition?.icon?.url;
  const showHeader = styles
    .getStylesForAllBreakpoints()
    .some((breakpointStyles) => breakpointStyles.booleans.showHeader);
  const showProgramContent = styles
    .getStylesForAllBreakpoints()
    .some((breakpointStyles) => breakpointStyles.booleans.showProgramContent);
  const socialMediaExperimentEnabled = experiments.enabled(Experiment.SocialMedia);
  const showSocialMedia = (isLoggedIn || !isViewer) && socialMediaExperimentEnabled;

  return (
    <div className={classes.root} data-hook={DataHook.LoyaltyPageWrapper}>
      {showHeader && (
        <Header
          loyaltyProgram={loyaltyProgram}
          isLoggedIn={tabState ? tabState === TabState.AfterSignUp : isLoggedIn}
          hasMyRewardsPage={hasMyRewardsPage}
          onNavigateToMyRewardsPage={onNavigateToMyRewardsPage}
        />
      )}
      {showProgramContent && (
        <ol className={classes.detailsContainer}>
          <DetailsCard
            followedChannels={followedChannels}
            dataHook={DataHook.DetailsCardSignUp}
            step={1}
            iconPath={iconPath ?? ''}
            title={settings.get(settingsParams.signUpTitle)}
            details={[{ description: settings.get(settingsParams.signUpDescription) }]}
            description={settings.get(settingsParams.signUpDescription)}
            showTiers={showTiers}
          />
          <DetailsCard
            followedChannels={followedChannels}
            dataHook={DataHook.DetailsCardEarnPoints}
            step={2}
            iconPath={iconPath ?? ''}
            title={settings.get(settingsParams.earnPointsTitle)}
            details={simpleRules.filter((rule) => !rule.tierId)}
            description={settings.get(settingsParams.earnPointsDescription)}
            showTiers={showTiers}
            onFollowSocialMediaChannel={showSocialMedia ? onFollowSocialMediaChannel : undefined}
          />
          <DetailsCard
            followedChannels={followedChannels}
            dataHook={DataHook.DetailsCardRedeemPoints}
            step={3}
            iconPath={iconPath ?? ''}
            title={settings.get(settingsParams.redeemPointsTitle)}
            details={simpleRewards.filter((reward) => !reward.tierId)}
            description={settings.get(settingsParams.redeemPointsDescription)}
            showTiers={showTiers}
          />
        </ol>
      )}
    </div>
  );
};
