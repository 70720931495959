import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';

import { DataHook } from '../../../types/data-hook';
import { Content } from './Content';
import { classes } from './EmptyState.st.css';

export const EmptyState = () => {
  const { t } = useTranslation();
  return (
    <Content dataHook={DataHook.EmptyState}>
      <Text className={classes.text}>{t('app.empty-state.program-not-availabe')}</Text>
    </Content>
  );
};
