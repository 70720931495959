import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';

import { DataHook } from '../../../types/data-hook';
import { Content } from './Content';
import { classes } from './ErrorState.st.css';

export const ErrorState = () => {
  const { t } = useTranslation();
  return (
    <Content dataHook={DataHook.ErrorState}>
      <Text tagName="h3" data-hook={DataHook.ErrorStateTitle} className={classes.title}>
        {t('app.error-state.service-not-available')}
      </Text>
      <Text tagName="p" data-hook={DataHook.ErrorStateDescription} className={classes.description}>
        {t('app.error-state.reload-page')}
      </Text>
    </Content>
  );
};
