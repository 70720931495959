import React, { PropsWithChildren, useMemo, useState } from 'react';

import { ToastConfig, ToastContext, ToastContextValue } from '../contexts';
import { Toast } from '../Toast';

export const ToastProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [toastConfig, setToastConfig] = useState<ToastConfig | null>(null);

  const value = useMemo(
    (): ToastContextValue => ({
      showToast: (config) => {
        setToastConfig(config);

        if (config.timeout) {
          setTimeout(() => {
            setToastConfig(null);
          }, config.timeout);
        }
      },
    }),
    [setToastConfig],
  );

  return (
    <ToastContext.Provider value={value}>
      {children}
      {toastConfig && (
        <Toast skin={toastConfig.skin} message={toastConfig.message} onClose={() => setToastConfig(null)} />
      )}
    </ToastContext.Provider>
  );
};
